import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-agri-marketing'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import ListReportHead from '.././ListReportHead.vue'
// import { dateFormat } from '@/Utils/fliter'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, newDatas, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRowsHead = [
          [
            { text: vm.$t('globalTrans.division'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getDivisionName(search.division_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('globalTrans.district'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getDistrictName(search.district_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('globalTrans.upazila'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getUpazilaNameCustom(search.upazila_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.market'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getMarketName(search.market_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.year'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.year_bn : search.year_en, alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.month'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.month_bn : search.month_en, alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.week'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.week_bn : search.week_en, alignment: 'left', style: 'search' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['16%', '1%', '83%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('damReport.commodity_group'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.product_name_and_description'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.measurement'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.producer_price'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        newDatas.forEach((info, index) => {
          allRows.push([
            { text: (i18n.locale === 'bn') ? info.group_name_bn : info.group_name, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.commodity_name_bn : info.commodity_name, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.unit_name_bn : info.unit_name, alignment: 'center', style: 'td' },
            { text: parseFloat(info.g_lowestPrice) || parseFloat(info.g_highestPrice) ? vm.$n(info.g_lowestPrice) + '-' + vm.$n(info.g_highestPrice) : '', alignment: 'center', style: 'td' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['25%', '25%', '25%', '25%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('weekly-market-wise-price-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
